import React, { useContext, useState, useEffect } from 'react';
import { withStyles, WithStyles, createStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { PlayerContext } from '../../../../../common/context';
import { StarRatingSpreadIndex } from '../../../../../common/interfaces/DetailedPlayer';
import BarChart from '../../../../../common/components/Charts/BarChart/BarChart';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../../../store';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    starRatingChart: {
        width: "97%",
        height: "85%",
        marginLeft: "1.5%",
        marginTop: "3%"
    }
});

type ClassKey = 'root' | "starRatingChart";
type PropsType = IProps & WithStyles<ClassKey>

const StarRatingSpreadChart: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const player = useContext(PlayerContext);
    const { useOldColors } = useSelector((state: IApplicationState) => state.settings);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [usedSRSpreadOptions, setUsedSRSpreadOptions] = useState<StarRatingSpreadIndex[]>([]);

    useEffect(() => {
        const srSpreadOptions: StarRatingSpreadIndex[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        setUsedSRSpreadOptions(srSpreadOptions.filter(option => {
            if (!isSmallScreen) return true;
            return player.data.star_ratings.find(b => b.min === option)?.count !== 0;
        }));
    }, [isSmallScreen, player.data.star_ratings])

    const chartLabels = usedSRSpreadOptions.map(option => option === 10 ? "10+" : `${option} - ${option + 1}`);
    const chartData = {
        label: "#1 count",
        data: usedSRSpreadOptions.map((option) => player.data.star_ratings.find(b => b.min === option)?.count ?? 0),
        backgroundColor: useOldColors ? "#82ca9d" : "#90ed7d"
    };

    return (
        <div className={classes.starRatingChart}>
            <BarChart labels={chartLabels} datasets={[chartData]} />
        </div>
    );
};

export default withStyles(styles)(StarRatingSpreadChart)