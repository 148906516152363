import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { PlayerContext } from '../../../../../common/context';
import BarChart from '../../../../../common/components/Charts/BarChart/BarChart';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../../../store';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    datesChart: {
        width: "97%",
        height: "85%",
        marginLeft: "1.5%",
        marginTop: "3%"
    }
})

type ClassKey = 'root' | "datesChart";
type PropsType = IProps & WithStyles<ClassKey>

const DatesChart: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const player = useContext(PlayerContext);
    const { useOldColors } = useSelector((state: IApplicationState) => state.settings);
    
    const datesSetKeys = player.data.dates_set.map(b => b.year);
    const datesRankedKeys = player.data.dates_ranked.map(b => b.year);
    const minYear = Math.min(...datesSetKeys, ...datesRankedKeys);
    const maxYear = Math.max(...datesSetKeys, ...datesRankedKeys);

    const chartLabels = [];
    for (let year = minYear; year <= maxYear; year++) {
        chartLabels.push(year.toString());
    }

    const chartDataSet = {
        label: "Dates set",
        data: chartLabels.map(option => player.data.dates_set.find(b => b.year === +option)?.count ?? 0),
        backgroundColor: useOldColors ? "#f7a35c" : "#F47A1F"
    };

    const chartDataRanked = {
        label: "Dates ranked",
        data: chartLabels.map(option => player.data.dates_ranked.find(b => b.year === +option)?.count ?? 0),
        backgroundColor: useOldColors ? "#8085e9" : "#9263c6"
    }

    return (
        <div className={classes.datesChart}>
            <BarChart labels={chartLabels} datasets={[chartDataSet, chartDataRanked]} />
        </div>
    );
}

export default withStyles(styles)(DatesChart)