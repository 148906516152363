import React, { useContext, useEffect, useState } from "react";
import { withStyles, WithStyles, createStyles, Theme, Accordion, AccordionSummary, Typography, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { CountryContext, GamemodeContext, PlayerContext } from "../../../../common/context";
import LoadingIcon from "../../../../common/components/LoadingIcon/LoadingIcon";
import { ESCountryTopScoreSnipe } from "../../../../common/interfaces/RecentChange";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from "lodash";
import { DateTime } from "luxon";
import axios from "axios";
import config from "../../../../common/config";

interface IProps {
    since: DateTime,
    until: DateTime,
    includeOwnSnipes: boolean
};

type ClassKey = "table" | "details";
type PropsType = IProps & WithStyles<ClassKey>;
const styles = (theme: Theme) => createStyles<ClassKey, Record<string, any>>({
    details: {
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    table: {
        width: "300%"
    }
});

const RecentChangesNew: React.FC<PropsType> = (props) => {
    const { classes, since, until, includeOwnSnipes } = props;
    const player = useContext(PlayerContext);
    const { code } = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [changes, setChanges] = useState<ESCountryTopScoreSnipe[]>();

    useEffect(() => {
        const fetch = async () => {
            const response = await axios.get<ESCountryTopScoreSnipe[]>(`${config.api}/changes/new/${code}/${player.user.id}?gamemode=${gamemode}&start_date=${since.toISO()}&end_date=${until.toISO()}&include_own_snipes=${includeOwnSnipes}`);
            setChanges(response.data);
        };

        if (expanded) fetch();
    }, [code, player.user.id, since, until, expanded, includeOwnSnipes, gamemode]);

    const headers = [
        { key: 'index', value: '' },
        { key: 'map', value: "Map name" },
        { key: "sniped_name", value: "Sniped" },
        { key: "mods", value: "Mods" },
        { key: "sr", value: "SR" },
        { key: "accuracy", value: "Accuracy" },
        { key: "date_set", value: "Date (UTC)" }
    ];

    const handleRowClick = (row: ESCountryTopScoreSnipe): void => {
        window.open(`https://osu.ppy.sh/scores/${row.new_topscore.score_id}`);
    };

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Recently set #1s</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {!changes
                    ? <LoadingIcon />
                    : (
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {headers.map(h => (
                                            <TableCell key={h.key}>{h.value}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {changes.map((row, index) => (
                                        <TableRow key={index} onClick={() => handleRowClick(row)} hover>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{row.beatmap.artist} - {row.beatmap.title} [{row.beatmap.version}]</TableCell>
                                            <TableCell>{row.old_topscore?.user?.username ?? "-"}</TableCell>
                                            <TableCell>{row.new_topscore.mods.map(m => m.acronym).join("")}</TableCell>
                                            <TableCell>{!!row.new_topscore.sr ? `${_.round(row.new_topscore.sr, 2)}*` : "-"}</TableCell>
                                            <TableCell>{_.round(row.new_topscore.accuracy * 100, 2)}%</TableCell>
                                            <TableCell>{DateTime.fromISO(row.new_topscore.date_set, { zone: "utc" }).toFormat("yyyy-MM-dd HH:mm")}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </AccordionDetails>
        </Accordion>
    );
};

export default withStyles(styles)(RecentChangesNew);
