import React, { useContext, useState, useEffect } from "react";
import { withStyles, WithStyles, createStyles, Theme, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableFooter, TableSortLabel } from "@material-ui/core";
import { CountryContext, GamemodeContext, PlayerContext } from "../../../../common/context";
import TableLinks from "../../../../common/components/OsuDirectLink/TableLinks";
import { ESCountryTopScore } from "../../../../common/interfaces/Score";
import LoadingIcon from "../../../../common/components/LoadingIcon/LoadingIcon";
import _ from "lodash";
import { DateTime } from "luxon";
import { convertGamemodeToString } from "../../../../common/helpers/generic-helpers";
import { getTopRanks, getTopRanksNextPage, getTopRanksPreviousPage } from "../../player-details-helper";

interface IProps {
    selectedColumns: { id: string, label: string, sortable?: boolean, maxWidth?: string }[];
    selectedMods: string;
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    osuDirectButton: {
        maxHeight: 0
    }
});

type ClassKey = "root" | "osuDirectButton";
type PropsType = IProps & WithStyles<ClassKey>;

const TopRanksTable: React.FC<PropsType> = (props) => {
    const { selectedColumns, selectedMods } = props;
    const country = useContext(CountryContext);
    const player = useContext(PlayerContext);
    const gamemode = useContext(GamemodeContext);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [topRanksData, setTopRanksData] = useState<{ total_hits: number, pages: Record<number, ESCountryTopScore[]> }>({ total_hits: 0, pages: {} });
    const [sortingField, setSortingField] = useState<string>("pp");
    const [sortingOrder, setSortingOrder] = useState<"asc" | "desc">("desc");

    useEffect(() => {
        const fetch = async () => {
            const { top_ranks, total_hits } = await getTopRanks(country.country_code, player.user.id, selectedMods, gamemode, sortingField, sortingOrder);
            setTopRanksData({ total_hits, pages: { 0: top_ranks } });
        };

        fetch();
    }, [country.country_code, player.user.id, selectedMods, gamemode, sortingField, sortingOrder]);

    const getNextPage = async () => {
        const currentPageData = topRanksData.pages[currentPage];
        const scoreToCompare = currentPageData[currentPageData.length - 1];
        if (!scoreToCompare) return;

        const newPage = currentPage + 1;
        setCurrentPage(newPage);

        if (!topRanksData.pages[newPage]) {
            const response = await getTopRanksNextPage(country.country_code, player.user.id, selectedMods, gamemode, sortingField, sortingOrder, scoreToCompare);
            setTopRanksData(old => ({ ...old, pages: { ...old.pages, [newPage]: response.top_ranks } }));
        }
    };

    const getPreviousPage = async () => {
        const scoreToCompare = topRanksData.pages[currentPage][0];
        if (!scoreToCompare) return;
        
        const newPage = currentPage - 1;
        setCurrentPage(newPage);

        if (!topRanksData.pages[newPage]) {
            const response = await getTopRanksPreviousPage(country.country_code, player.user.id, selectedMods, gamemode, sortingField, sortingOrder, scoreToCompare);
            setTopRanksData(old => ({ ...old, pages: { ...old.pages, [newPage]: response.top_ranks } }));
        }
    };

    const handleRowClick = (row: ESCountryTopScore): void => {
        window.open(`https://osu.ppy.sh/scores/${row.top_score.score_id}`);
    };

    const handlePageChange = (page: number) => {
        if (currentPage < page) getNextPage();
        else getPreviousPage();
    };

    const handleSortingFieldChange = (newSortingField: string) => {
        const isDesc = newSortingField === sortingField && sortingOrder === "desc";
        setSortingField(newSortingField);
        setSortingOrder(isDesc ? "asc" : "desc");
    };

    const showColumn = (key: string): boolean => selectedColumns.some(c => c.id === key);

    return (
        <TableContainer>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        {selectedColumns.map((c, i) => (
                            <TableCell key={c.id}>
                                {!!c.sortable ? (
                                    <TableSortLabel active={sortingField === c.id} direction={sortingField === c.id ? sortingOrder : "desc"} onClick={() => handleSortingFieldChange(c.id)}>
                                        {c.label}
                                    </TableSortLabel>
                                ) : c.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {!topRanksData?.pages?.[currentPage] && (
                        <TableRow>
                            <TableCell colSpan={selectedColumns.length}>
                                <LoadingIcon />
                            </TableCell>
                        </TableRow>
                    )}

                    {topRanksData?.pages?.[currentPage]?.map((row, index) => {
                        const minutes = Math.floor(row.top_score.total_length / 60);
                        return (
                            <TableRow key={index} onClick={() => handleRowClick(row)} hover>
                                {showColumn("rank") && (
                                    <TableCell>{currentPage * 50 + index + 1}</TableCell>
                                )}
                                {showColumn("map_name") && (
                                    <TableCell>{row.beatmap.artist} - {row.beatmap.title} [{row.beatmap.version}]</TableCell>
                                )}

                                {showColumn("gamemode") && <TableCell>{convertGamemodeToString(row.gamemode)}</TableCell>}

                                {showColumn("mods") && (
                                    <TableCell>{row.top_score.mods.map(m => m.acronym).join("")}</TableCell>
                                )}

                                {showColumn("sr") && <TableCell>{!!row.top_score.sr ? _.round(row.top_score.sr, 2) : "-"}*</TableCell>}
                                {showColumn("bpm") && <TableCell>{_.round(row.top_score.bpm, 2)}</TableCell>}
                                {showColumn("ar") && <TableCell>{_.round(row.top_score.ar, 2)}</TableCell>}
                                {showColumn("cs") && <TableCell>{_.round(row.top_score.cs, 2)}</TableCell>}
                                {showColumn("od") && <TableCell>{_.round(row.top_score.od, 2)}</TableCell>}
                                {showColumn("hp") && <TableCell>{_.round(row.top_score.hp, 2)}</TableCell>}
                                {showColumn("total_length") && <TableCell>{minutes > 0 ? `${minutes}m${Math.round(row.top_score.total_length - (minutes * 60))}s` : `${Math.round(row.top_score.total_length)}s`}</TableCell>}
                                {showColumn("score") && <TableCell>{row.top_score.score.toLocaleString()}</TableCell>}
                                {showColumn("accuracy") && <TableCell>{_.round(row.top_score.accuracy * 100, 2)}%</TableCell>}
                                {showColumn("perfect") && <TableCell>{row.top_score.statistics.perfect}</TableCell>}
                                {showColumn("great") && <TableCell>{row.top_score.statistics.great}</TableCell>}
                                {showColumn("good") && <TableCell>{row.top_score.statistics.good}</TableCell>}
                                {showColumn("ok") && <TableCell>{row.top_score.statistics.ok}</TableCell>}
                                {showColumn("meh") && <TableCell>{row.top_score.statistics.meh}</TableCell>}
                                {showColumn("miss") && <TableCell>{row.top_score.statistics.miss}</TableCell>}
                                {showColumn("pp") && <TableCell>{_.round(row.top_score.pp)}</TableCell>}
                                {showColumn("date_set") && <TableCell>{DateTime.fromISO(row.top_score.date_set).toFormat("yyyy-MM-dd")}</TableCell>}
                                {showColumn("date_ranked") && <TableCell>{DateTime.fromISO(row.beatmap.date_ranked).toFormat("yyyy-MM-dd")}</TableCell>}
                                {showColumn("count_normal") && <TableCell>{row.beatmap.count_normal}</TableCell>}
                                {showColumn("count_slider") && <TableCell>{row.beatmap.count_slider}</TableCell>}
                                {showColumn("count_spinner") && <TableCell>{row.beatmap.count_spinner}</TableCell>}
                                {showColumn("links") && <TableCell><TableLinks osuDirect mapId={row.beatmap.id} /></TableCell>}
                            </TableRow>
                        );
                    })}
                </TableBody>

                <TableFooter>
                    <TablePagination
                        count={topRanksData.total_hits}
                        page={currentPage}
                        onChangePage={(_, page) => handlePageChange(page)}
                        rowsPerPage={50}
                        rowsPerPageOptions={[]}
                    />
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default withStyles(styles)(TopRanksTable);
