import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Grid, Paper } from '@material-ui/core';
import ModsPieChart from './ModsPieChart/ModsPieChart';
import StarRatingSpreadChart from './StarRatingSpreadChart/StarRatingSpreadChart';
import HistoryAreaChart from './HistoryAreaChart/HistoryAreaChart';
import { PlayerContext } from '../../../../common/context';
import DatesChart from './DatesChart/DatesChart';
import RanksChart from './RanksChart/RanksChart';
import PlayerDetailsChartHeader from './PlayerDetailsChartHeader';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    gridContainer: {

    },
    gridItem: {
        height: 500,
        paddingTop: theme.spacing(1)
    },
    gridItemModsChart: {
        [theme.breakpoints.up('sm')]: {
            height: 500
        },
        [theme.breakpoints.down('xs')]: {
            height: 600
        },
        paddingTop: theme.spacing(1)
    }
});

type ClassKey = 'root' | "gridContainer" | "gridItem" | "gridItemModsChart";
type PropsType = IProps & WithStyles<ClassKey>

const PlayerDetailsCharts: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const player = useContext(PlayerContext);

    const tooltipsText = {
        mods: [
            "The left graph shows an overview of all used mod combinations and how often each was used.",
            "The right graphs show the most frequently mods and how often they appeared in any used mod combination"
        ],
        srSpread: ["This graph shows how many #1s you have within each SR range"],
        ranks: ["This graph shows an overview of how your ranks on these leaderboards are distributed"],
        dates: ["Orange: #1s set in this year", "Purple: #1s on maps ranked/loved in this year"],
        history: ["This graph shows how many #1s you used to have in the past"]
    };

    return (
        <Grid container className={classes.gridContainer} spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper className={classes.gridItemModsChart}>
                    <PlayerDetailsChartHeader headerText="Mods" tooltipText={tooltipsText.mods} />
                    {/* <ModsPieChart /> */}
                </Paper>
            </Grid>
            {player.data.star_ratings && (
                <Grid item xs={12} md={6}>
                    <Paper className={classes.gridItem}>
                        <PlayerDetailsChartHeader headerText="Star rating spread" tooltipText={tooltipsText.srSpread} />
                        <StarRatingSpreadChart />
                    </Paper>
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <Paper className={classes.gridItem}>
                    <PlayerDetailsChartHeader headerText="Ranks" tooltipText={tooltipsText.ranks} />
                    <RanksChart />
                </Paper>
            </Grid>
            {(player.data.dates_ranked || player.data.dates_set) && (
                <Grid item xs={12} md={6}>
                    <Paper className={classes.gridItem}>
                        <PlayerDetailsChartHeader headerText="Dates" tooltipText={tooltipsText.dates} />
                        <DatesChart />
                    </Paper>
                </Grid>
            )}
            <Grid item xs={12}>
                <Paper className={classes.gridItem}>
                    <PlayerDetailsChartHeader headerText="History" tooltipText={tooltipsText.history} />
                    <HistoryAreaChart />
                </Paper>
            </Grid>
        </Grid >
    )
}

export default withStyles(styles)(PlayerDetailsCharts)