import React, { useState } from "react";
import { withStyles, WithStyles, createStyles, Theme, Typography, Paper, Button } from "@material-ui/core";
import TopRanksTable from "./TopRanksTable";
import MultiSelectBox from "../../../../common/components/SelectBoxes/MultiSelectBox";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../store";
import localforage from "localforage";
import TopRanksCollectionDialog from "./TopRanksCollectionDialog";

interface ColumnOption {
    id: string;
    label: string;
    default?: boolean;
    disabled?: boolean;
    sortable?: boolean;
    maxWidth?: string;
};

interface IProps { };

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        textAlign: "left"
    },
    paper: {
        paddingTop: theme.spacing(1)
    },
    title: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    selectBoxStyling: {
        [theme.breakpoints.up("md")]: {
            width: "20%",
        }
    },
    topRanksOptions: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
        paddingLeft: 8,
        paddingRight: 8
    }
});

type ClassKey = "root" | "paper" | "title" | "selectBoxStyling" | "topRanksOptions";
type PropsType = IProps & WithStyles<ClassKey>;

const TopRanks: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const settings = useSelector((state: IApplicationState) => state.settings);
    // const [selectedMods, setMods] = useState<string>("");
    const selectedMods = "";
    const [collectionDialogOpen, setCollectionDialogOpen] = useState<boolean>(false);

    const columnOptions: ColumnOption[] = [
        { id: "rank", label: "Rank", default: true, disabled: true },
        { id: "map_name", label: "Map", default: true, disabled: true, sortable: false },
        { id: "gamemode", label: "Gamemode", default: true, sortable: false },
        { id: "mods", label: "Mods", default: true, sortable: true, maxWidth: "1%" },
        { id: "sr", label: "SR", default: true, sortable: true, maxWidth: "1%" },
        { id: "bpm", label: "BPM", sortable: true, maxWidth: "1%" },
        { id: "ar", label: "AR", sortable: true, maxWidth: "1%" },
        { id: "cs", label: "CS", sortable: true, maxWidth: "1%" },
        { id: "od", label: "OD", sortable: true, maxWidth: "1%" },
        { id: "hp", label: "HP", sortable: true, maxWidth: "1%" },
        { id: "total_length", label: "Length", sortable: true, maxWidth: "1%" },
        { id: "score", label: "Score", sortable: true, maxWidth: "1%" },
        { id: "accuracy", label: "Accuracy", default: true, sortable: true, maxWidth: "1%" },
        { id: "ok", label: "100", default: true, sortable: true, maxWidth: "1%" },
        { id: "meh", label: "50", default: true, sortable: true, maxWidth: "1%" },
        { id: "miss", label: "Miss", default: true, sortable: true, maxWidth: "1%" },
        { id: "pp", label: "pp", default: true, sortable: true, maxWidth: "5%" },
        { id: "date_set", label: "Date set", sortable: true, maxWidth: "6%" },
        { id: "date_ranked", label: "Date ranked", sortable: true, maxWidth: "7%" },
        { id: "count_normal", label: "Circles", sortable: false, maxWidth: "1%" },
        { id: "count_slider", label: "Sliders", sortable: false, maxWidth: "1%" },
        { id: "count_spinner", label: "Spinners", sortable: false, maxWidth: "1%" },
        { id: "links", label: "osu!direct", default: true, maxWidth: "1%" }
    ];

    if (!!settings.topRanksColumns.length)
        columnOptions.forEach(c => c.default = settings.topRanksColumns.includes(c.id as any));

    const [selectedColumns, setSelectedColumns] = useState<{ id: string, label: string, sortable?: boolean }[]>(columnOptions.filter(c => !!c.default));

    // const handleModsChange = (mods: Mods): void => {
    //     let modsString = "";
    //     Object.keys(mods).forEach((mod) => {
    //         if (mods[mod as keyof Mods])
    //             modsString += mod;
    //     });
    //     setMods(modsString);
    // };

    const handleColumnsChange = (columns: string[]): void => {
        setSelectedColumns(columnOptions.filter(c => columns.includes(c.id)));
        localforage.setItem("settings", { ...settings, topRanksColumns: columns });
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h6" className={classes.title}>
                    Top Ranks
                </Typography>
                <Button onClick={() => setCollectionDialogOpen(true)} variant="contained" color="primary" style={{ margin: 8 }}>
                    Write to collection.db
                </Button>

                <div className={classes.topRanksOptions}>
                    {/* <ModsSelector update={handleModsChange} shouldApply /> */}
                    <div />
                    <MultiSelectBox label="Columns" items={columnOptions} handleValueChange={handleColumnsChange} selectBoxStyling={classes.selectBoxStyling} />
                </div>
                <TopRanksTable selectedColumns={selectedColumns} selectedMods={selectedMods} />
            </Paper>

            {collectionDialogOpen && <TopRanksCollectionDialog open={collectionDialogOpen} onClose={() => setCollectionDialogOpen(false)} />}
        </div>
    );
};

export default withStyles(styles)(TopRanks);
