import React, { useContext, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { CountryContext, GamemodeContext } from '../../../../common/context';
import { useFetch } from '../../../../common/hooks/useAPICall';
import { RankingsPlayer, RankingsPlayerOldestDate } from '../../../../common/interfaces/RankingsPlayer';
import RankingsTable from '../RankingsTable/RankingsTable';
import moment from 'moment';
import { convertDateFormat, convertGamemodeToString } from '../../../../common/helpers/generic-helpers';
import TableLinks from '../../../../common/components/OsuDirectLink/TableLinks';
import LoadingIcon from '../../../../common/components/LoadingIcon/LoadingIcon';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../../store';
import RankingsFilters from '../RankingsFilters/RankingsFilters';
import StyledName from '../../../../common/components/StyledName/StyledName';
import { DateTime } from 'luxon';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const OldestDateRankings: React.FC<PropsType> = (props) => {
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const { settings } = useSelector((state: IApplicationState) => state);
    const gamemodeString = convertGamemodeToString(gamemode);
    const [searchValue, setSearchValue] = useState<string>('');
    const url = `rankings/${country.code}/${gamemodeString}/oldest_topscore`;
    const { response: rankings, loading } = useFetch<RankingsPlayer[]>(`${url}`);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    if (loading || !(rankings instanceof Array))
        return <LoadingIcon />;

    const parsedRankings = rankings.map((player, i) => ({
        ...player,
        user_id: player.user.id,
        name: <StyledName user_id={player.user.id} name={player.user.username} donator={false} />,
        date: DateTime.fromISO(player.data.oldest_topscore?.date_set ?? "").toFormat(isSmallScreen ? convertDateFormat(settings.dateFormat) : 'MMMM dd, yyyy - hh:mm'),
        map_id: player.data.oldest_topscore?.map_id,
        map: `${player.data.oldest_topscore?.artist} - ${player.data.oldest_topscore?.title} [${player.data.oldest_topscore?.version}]`,
        rank: player.data.ranks?.oldest_topscore
    }));

    const headers = [
        { key: "rank", value: "Rank" },
        { key: "name", value: "Player" },
        { key: "date", value: "Date oldest #1" },
        { key: "map", value: "Map" },
        { key: "links", value: "" }
    ];

    // if (settings.showMapsInOldestDate)
    //     headers.splice(4, 0, ...[
    //         { key: "parsed_mods", value: "Mods" },
    //         { key: "parsed_accuracy", value: "Accuracy" },
    //         { key: "count_spinner", value: "Spinners" },
    //     ]);

    const rows = parsedRankings.map(player => ({
        key: player.map,
        values: {
            ...player,
            // parsed_mods: player.mods === "nomod" ? "" : player.mods,
            // parsed_accuracy: `${+((player.accuracy ?? 0) * 100).toFixed(2)}%`,
            links: <TableLinks beatmapPage osuDirect mapId={player.map_id} />
        },
    }));

    const update = (newSearchValue: string | null) => {
        if (newSearchValue !== null) setSearchValue(newSearchValue);
    };

    return (
        <>
            <RankingsFilters update={update} searchDelay={500} />
            <RankingsTable headers={headers} rows={rows} mainField='date' loading={loading} hideSearchbar />
        </>
    );
}

export default withStyles(styles)(OldestDateRankings)