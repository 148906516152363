import React, { useContext, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Tabs, Tab, Typography, Box, useMediaQuery, useTheme } from '@material-ui/core';
import ToolBar from '../../common/components/ToolBar/ToolBar';
import { useFetch } from '../../common/hooks/useAPICall';
import { CountryContext, PlayerContext, GamemodeContext } from '../../common/context';
import { useLocation } from 'react-router-dom';
import StatisticsPanel from '../../common/components/StatisticsPanel/StatisticsPanel';
import { usePlayerDataRounder } from '../../common/hooks/usePlayerDataRounder';
import TopRanks from './components/TopRanks/TopRanks';
import PlayerDetailsCharts from './components/PlayerDetailsCharts/PlayerDetailsCharts';
import RecentChanges from './components/RecentChanges/RecentChanges';
import { convertGamemodeToString } from '../../common/helpers/generic-helpers';
import { formatPlayerRank } from '../../common/helpers/player-helpers';
import i18n from '../../i18n';
import LoadingIcon from '../../common/components/LoadingIcon/LoadingIcon';
import StyledName from '../../common/components/StyledName/StyledName';
import { ESPlayerData } from '../../common/interfaces/DetailedPlayer';
import { round } from 'lodash';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    statisticsPanelContainer: {
        width: "calc(100% - 24px)",
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(2)
    },
    tabsContainer: {
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
            display: "flex",
        }
    },
    tabs: {
        minWidth: "15%",
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.background.paper,
            width: "calc(100% - 24px)",
        },
        color: theme.palette.text.primary
    },
    tabPanel: {
        width: "100%",
        marginTop: theme.spacing(1)
    },
    donator: {
        color: "goldenrod"
    }
})

type ClassKey = 'root' | "statisticsPanelContainer" | "tabsContainer" | "tabs" | "tabPanel" | "donator";
type PropsType = IProps & WithStyles<ClassKey>

const PlayerDetails: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const [selectedTab, setSelectedTab] = useState(0);
    const location = useLocation();
    const { response, loading } = useFetch<ESPlayerData>(`player/stats/${country.code}/${location.pathname.split('/').pop()}/${gamemodeString}`);
    const theme = useTheme();
    const tabsOrientation = useMediaQuery(theme.breakpoints.up('md')) ? "vertical" : "horizontal";

    if (loading)
        return <LoadingIcon />;

    if (!response?.user)
        return <>Player not found</>;

    const player = response;

    const statisticsTiles = [
        { label: i18n.t("playerDetails:stats.count"), value: player.data.count_total.toLocaleString(), hover: `#${player.data.ranks?.count_total}` },
        { label: i18n.t("playerDetails:stats.weighted_pp"), value: `${Math.round(player.data.weighted_pp).toLocaleString()}pp`, hover: formatPlayerRank(player.data.ranks?.weighted_pp) },
        { label: i18n.t("playerDetails:stats.average_pp"), value: `${round(player.data.average_pp, 2)}pp`, hover: formatPlayerRank(player.data.ranks?.average_pp) },
        { label: i18n.t("playerDetails:stats.average_sr"), value: `${round(player.data.average_sr, 2).toLocaleString()}*`, hover: formatPlayerRank(player.data.ranks?.average_sr) },
        { label: i18n.t("playerDetails:stats.average_acc"), value: `${round(player.data.average_accuracy * 100, 2).toLocaleString()}%`, hover: formatPlayerRank(player.data.ranks?.average_accuracy) },
        { label: i18n.t("playerDetails:stats.total_score"), value: player.data.total_legacy_score.toLocaleString(), hover: formatPlayerRank(player.data.ranks?.total_legacy_score) },
        { label: i18n.t("playerDetails:stats.average_score"), value: Math.round(player.data.average_legacy_score).toLocaleString(), hover: formatPlayerRank(player.data.ranks?.average_legacy_score) }
    ];

    const handleTabChange = (_: any, newValue: number) => {
        setSelectedTab(newValue);
    };

    function TabPanel(props: any) {
        const { children, index } = props;
        return (
            <Typography className={classes.tabPanel} component="div" role="tabpanel" hidden={selectedTab !== index}>
                {selectedTab === index && <Box p={1.5}>{children}</Box>}
            </Typography>
        )
    };

    const handleNameClick = () => {
        window.open(`https://osu.ppy.sh/u/${player.user.id}`);
    };

    return (
        <PlayerContext.Provider value={player}>
            <ToolBar shortTitle={i18n.t("playerDetails:toolbar.shortTitle")} longTitle={i18n.t("playerDetails:toolbar.longTitle", { playerName: player.user.username })} />
            <div className={classes.statisticsPanelContainer}>
                <StatisticsPanel xs={1} sm={1} md={2} lg={1} mainComponent={<StyledName user_id={player.user.id} name={player.user.username} donator={false} variant="h6" />} mainLabel={player.user.username} tiles={statisticsTiles} onMainClick={handleNameClick} />
            </div>
            <div className={classes.tabsContainer}>
                <Tabs indicatorColor="primary" orientation={tabsOrientation} variant="scrollable" value={selectedTab} onChange={handleTabChange} className={classes.tabs}>
                    <Tab label={i18n.t("playerDetails:tabs.charts") as string} />
                    <Tab label={i18n.t("playerDetails:tabs.recentActivity") as string} />
                    <Tab label={i18n.t("playerDetails:tabs.topRanks") as string} />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    <PlayerDetailsCharts />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <RecentChanges />
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    <TopRanks />
                </TabPanel>
            </div>
        </PlayerContext.Provider>
    )
}

export default withStyles(styles)(PlayerDetails)
