import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme, fade, useTheme, useMediaQuery } from '@material-ui/core';
import { PlayerContext } from '../../../../../common/context';
import RadarChart from '../../../../../common/components/Charts/RadarChart/RadarChart';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../../../store';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    ranksChart: {
        width: "95%",
        height: "90%",
        marginLeft: "1.5%",
        marginTop: -10
    }
})

type ClassKey = 'root' | "ranksChart";
type PropsType = IProps & WithStyles<ClassKey>

const RanksChart: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const player = useContext(PlayerContext);
    const { theme, useOldColors } = useSelector((state: IApplicationState) => state.settings);
    const matTheme = useTheme();
    const isSmallScreen = useMediaQuery(matTheme.breakpoints.down('xs'));

    const average = isSmallScreen ? "Av." : "Average";
    const chartLabels = ["Count", "Weighted pp", `${average} pp`, `${average} SR`, `${average} score`, "Total score"];

    if (!player.data.ranks) return null;

    const { weighted_pp, count_total, average_pp, average_sr, average_legacy_score, total_legacy_score } = player.data.ranks;
    
    const calculateRankScore = (rank: number | null) => !rank ? 0 : Math.max((250 - (rank - 1)) / 5, 0);

    const color = theme === "dark"
        ? (useOldColors ? "#e4d354" : "#FDBB2F")
        : "#f45b5b";
    
    const ranks = [count_total, weighted_pp, average_pp, average_sr, average_legacy_score, total_legacy_score];
    const rankScores = ranks.map(rank => calculateRankScore(rank));
    const chartData = {
        label: "Ranks",
        data: rankScores,
        ranks,
        borderColor: color,
        backgroundColor: fade(color, 0.2),
    };

    return (
        <div className={classes.ranksChart}>
            <RadarChart labels={chartLabels} datasets={[chartData]} />
        </div>
    );
}

export default withStyles(styles)(RanksChart)