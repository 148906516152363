import React, { useContext, useState } from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { useFetch } from "../../../../common/hooks/useAPICall";
import { CountryContext, GamemodeContext } from "../../../../common/context";
import { usePlayerDataRounder } from "../../../../common/hooks/usePlayerDataRounder";
import RankingsTable from "../RankingsTable/RankingsTable";
import { convertGamemodeToString } from "../../../../common/helpers/generic-helpers";
import LoadingIcon from "../../../../common/components/LoadingIcon/LoadingIcon";
import RankingsFilters from "../RankingsFilters/RankingsFilters";
import StyledName from "../../../../common/components/StyledName/StyledName";
import { RankingsPlayer, RankingsPlayerWeightedPP } from "../../../../common/interfaces/RankingsPlayer";

interface IProps { }

const styles = (theme: Theme) =>
    createStyles<ClassKey, {}>({
        root: {}
    });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const WeightedPPRankings: React.FC<PropsType> = props => {
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const [searchValue, setSearchValue] = useState<string>('');
    const { response: rankings, loading } = useFetch<RankingsPlayer[]>(`rankings/${country.code}/${gamemodeString}/weighted_pp`);

    if (loading || !rankings)
        return <LoadingIcon />;

    const headers = [
        { key: country.code === "all" ? "rank_weighted_pp_combined" : "rank_weighted_pp", value: 'Rank' },
        { key: 'name', value: 'Name' },
        { key: 'weighted_pp', value: 'Weighted pp' },
        { key: 'count_total', value: '#1 count' },
        { key: 'average_pp', value: 'Average pp' },
        { key: 'average_sr', value: 'Average SR' }
    ];

    const rows = rankings.map((player, index) => ({
        key: player.user.id,
        values: {
            ...player,
            ...player.data,
            name: <StyledName user_id={player.user.id} name={player.user.username} donator={false} country={country.code === "all" ? player.country : undefined} />,
            country: player.country?.toUpperCase() || "",
            rank_weighted_pp: player.data.ranks?.weighted_pp
        }
    }));

    const update = (newSearchValue: string | null) => {
        if (newSearchValue !== null) setSearchValue(newSearchValue);
    };

    return (
        <>
            <RankingsFilters update={update} showSearchBar searchDelay={500} />
            <RankingsTable headers={headers} rows={rows} mainField='weighted_pp' loading={loading} />
        </>
    );
};

export default withStyles(styles)(WeightedPPRankings);
