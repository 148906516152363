import React, { ChangeEvent, useContext, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Dialog, DialogTitle, DialogContent, Typography, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, DialogActions } from '@material-ui/core';
import { PlayerContext } from '../../../../common/context';
import config from '../../../../common/config';
import { startDownload } from '../../../../common/helpers/generic-helpers';
import LoadingIcon from '../../../../common/components/LoadingIcon/LoadingIcon';

interface IProps {
    open: boolean,
    onClose: () => void
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
});

type ClassKey = 'root';
type PropsType = IProps & WithStyles<ClassKey>;

const TopRanksCollectionDialog: React.FC<PropsType> = (props) => {
    const { open, onClose } = props;
    const player = useContext(PlayerContext);
    const [collectionName, setCollectionName] = useState<string>(`${player.user.username}'s ${player.country.toUpperCase()} #1s`);
    const [selectedOption, setSelectedOption] = useState<string>("update");
    const [collectionDb, setCollectionDb] = useState<File>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) return;
        setCollectionDb(e.target.files[0]);
    };

    const confirm = async () => {
        if (selectedOption === "update" && !collectionDb) return;
        setLoading(true);

        const requestCollection = async (reader?: FileReader) => {
            const res = await fetch(`${config.api}/player/${player.country}/${player.user.id}/collection`, {
                method: "POST",
                body: JSON.stringify({
                    file: reader?.result ?? null,
                    name: collectionName,
                    type: selectedOption
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const r = res.body?.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    return pump();
                    function pump(): any {
                        return r?.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }

                            controller.enqueue(value);
                            return pump();
                        })
                    }
                }
            });

            const blob = await new Response(stream).blob();
            const finalReader = new FileReader();
            finalReader.onloadend = () => {
                startDownload(finalReader.result as string, "collection", "db");
                onClose();
                setLoading(false);
            };
            finalReader.readAsDataURL(blob);
        };

        if (selectedOption === "update" && !!collectionDb) {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(collectionDb);
                reader.onload = () => requestCollection(reader);
            } catch (error) {
                console.error(error);
            }
        }
        else
            requestCollection();
    };

    return (
        <Dialog open={open} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="md">
            <DialogTitle>Generate collection</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Here you can generate a collection with all of this player's topranks for in osu!. This works by either modifying your existing collection.db file, or creating a new one if you so wish.
                </Typography>

                <TextField value={collectionName} onChange={e => setCollectionName(e.target.value)} label="Collection name" fullWidth style={{ marginTop: 16 }} disabled={loading} />

                <FormControl style={{ marginTop: 16 }}>
                    <FormLabel>Please choose one of the following:</FormLabel>
                    <RadioGroup value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
                        <FormControlLabel value="update" control={<Radio color="primary" disabled={loading} />} label="Update your existing collection.db" />
                        <FormControlLabel value="create" control={<Radio color="primary" disabled={loading} />} label="Create a new collection.db" />
                    </RadioGroup>
                </FormControl>

                {selectedOption === "update" && (
                    <>
                        <ul>
                            <li>
                                <Typography variant="body1" color="error">
                                    Please create a back-up of your collection.db first, so you can always go back to that one if something goes wrong. Unfortunately I cannot guarantee this feature will work since the collection.db just corrupts itself sometimes!
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" color="error">
                                    If your current collection.db already contains a collection with the chosen name, then it will be overwritten!!
                                </Typography>
                            </li>
                        </ul>
                        <Button component="label" variant="contained" color="primary" disabled={loading}>
                            Upload your collection.db file
                            <input type="file" accept=".db" onChange={handleFileUpload} hidden disabled={loading} />
                        </Button>
                        {!!collectionDb && (
                            <Typography variant="caption" style={{ marginLeft: 8 }}>Uploaded {collectionDb.name}</Typography>
                        )}
                    </>
                )}

                {loading && (
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <LoadingIcon />
                        <Typography variant="caption" style={{ marginTop: 32 }}>
                            This might take a while for players with a lot of top ranks
                        </Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onClose} disabled={loading}>Cancel</Button>
                <Button variant="contained" onClick={confirm} disabled={(selectedOption === "update" && !collectionDb) || loading} color="primary">Download collection.db</Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(TopRanksCollectionDialog);
