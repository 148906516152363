import React, { PropsWithChildren } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Tooltip } from '@material-ui/core';

interface IProps {
    title: string[];
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        fontSize: 13
    }
});

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const TooltipText: React.FC<PropsType> = (props) => {
    const { title } = props;

    return (
        <div>
            {title.map((line, index) => <div key={index}>{line}</div>)}
        </div>
    );
};

const BetterTooltip: React.FC<PropsType & PropsWithChildren> = (props) => {
    const { classes, children } = props;

    return (
        <Tooltip title={<span className={classes.root}><TooltipText {...props} /></span>} enterTouchDelay={0} leaveTouchDelay={5000}>
            <div>{children}</div>
        </Tooltip>
    );
};

export default withStyles(styles)(BetterTooltip)