import React, { useContext, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { CountryContext, GamemodeContext } from '../../../../common/context';
import { convertGamemodeToString, formatNumber } from '../../../../common/helpers/generic-helpers';
import { useFetch } from '../../../../common/hooks/useAPICall';
import { usePlayerDataRounder } from '../../../../common/hooks/usePlayerDataRounder';
import LoadingIcon from '../../../../common/components/LoadingIcon/LoadingIcon';
import StyledName from '../../../../common/components/StyledName/StyledName';
import RankingsFilters from '../RankingsFilters/RankingsFilters';
import RankingsTable from '../RankingsTable/RankingsTable';
import { RankingsPlayer, RankingsPlayerTotalScore } from '../../../../common/interfaces/RankingsPlayer';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const TotalScorRankings: React.FC<PropsType> = (props) => {
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const [searchValue, setSearchValue] = useState<string>('');
    const { response: rankings, loading } = useFetch<RankingsPlayer[]>(`rankings/${country.code}/${gamemodeString}/total_legacy_score`);

    if (loading || !rankings)
        return <LoadingIcon />;

    const headers = [
        { key: country.code === "all" ? "rank_total_score_combined" : "rank_total_score", value: "Rank" },
        { key: "name", value: "Name" },
        { key: "total_score_formatted", value: "Total score" },
        { key: "weighted_pp", value: "Weighted pp" },
        { key: "count_total", value: "#1 count" }
    ];

    const rows = rankings.map((player, index) => ({
        key: player.user.id,
        values: {
            ...player,
            ...player.data,
            name: <StyledName user_id={player.user.id} name={player.user.username} donator={false} country={country.code === "all" ? player.country : undefined} />,
            total_score_formatted: formatNumber(player.data.total_legacy_score),
            country: player.country?.toUpperCase() || "",
            rank_total_score: player.data.ranks?.total_legacy_score
        }
    }));

    const update = (newSearchValue: string | null) => {
        if (newSearchValue !== null) setSearchValue(newSearchValue);
    };

    return (
        <>
            <RankingsFilters update={update} showSearchBar searchDelay={500} />
            <RankingsTable headers={headers} rows={rows} mainField="total_score_formatted" loading={loading} />
        </>
    );
};

export default withStyles(styles)(TotalScorRankings)