import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

interface IProps { };

const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
};

function getAnimationSettings(color: string) {
    return {
        particleCount: 1,
        startVelocity: 0,
        ticks: 200,
        spread: 360,
        origin: {
            x: Math.random(),
            y: Math.random() * 0.999 - 0.2
        },
        colors: [color]
    };
}

const Confetti: React.FC<IProps> = (props) => {
    const now = moment();
    const birthdays = [
        { day: 25, month: 5, color: "#bb5555" },
        { day: 20, month: 11, color: "#d834eb" },
        { day: 10, month: 12, color: "#3498eb" },
    ];

    const currentBirthday = birthdays.find(b => now.date() === b.day && now.month() === b.month - 1);

    const refAnimationInstance = useRef<any>(null);
    const nextTickAnimation = useCallback(() => {
        if (refAnimationInstance.current) {
            refAnimationInstance.current(getAnimationSettings(currentBirthday?.color ?? ""));
            refAnimationInstance.current(getAnimationSettings(currentBirthday?.color ?? ""));
        }
    }, [currentBirthday]);

    const [intervalId] = useState<any>(setInterval(nextTickAnimation, 400));

    const getInstance = useCallback((instance: any) => {
        refAnimationInstance.current = instance;
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalId]);

    if (!currentBirthday) return null;

    return (
        <>
            <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles as any} />
        </>
    );
};

export default Confetti;