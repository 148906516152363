import config from "../../common/config";
import { Gamemode } from "../../common/interfaces/Gamemode";
import { Mod, ModsData } from "../../common/interfaces/Mods";
import { ESCountryTopScore, PlayerTopRanksResponse } from "../../common/interfaces/Score";
import axios from "axios";

export interface DifficultyAttributes {
    ar: number;
    cs: number;
    od: number;
    hp: number;
};

export const combineMods = (modsData: ModsData[], oldMod: string, newMod: string): void => {
    modsData.forEach(modCombination => {
        if (modCombination.name.includes(oldMod)) {
            const newName = modCombination.name.replace(oldMod, newMod);
            const oldModsData = modsData.find(m => m.name === newName);
            oldModsData ? oldModsData.value += modCombination.value : modsData.push({ name: newName, value: modCombination.value });
            modCombination.value = 0;
        }
    });
};

export const ignoreMods = (modsData: ModsData[], modsToIgnore: string[]): void => {
    modsData.forEach(modCombination => {
        let newName = modsToIgnore.reduce((total, current) => total.replace(current, ""), modCombination.name);
        if (newName === "") newName = "nomod";
        if (newName !== modCombination.name) {
            const existingModsData = modsData.find(m => m.name === newName);
            existingModsData ? existingModsData.value += modCombination.value : modsData.push({ name: newName, value: modCombination.value });
            modCombination.value = 0;
        }
    });
};

const getSortValueFromSortField = (score: ESCountryTopScore, field: string): string | number | null => {
    switch (field) {
        case "sr": case "bpm": case "ar": case "cs": case "od": case "hp": case "total_length": case "score": case "accuracy": case "pp": case "date_set":
            return score.top_score[field];
        case "perfect": case "great": case "good": case "ok": case "meh": case "miss":
            return score.top_score.statistics[field] ?? null;
        case "date_ranked": case "count_normal": case "count_slider": case "count_spinner":
            return score.beatmap[field];
        default: return null;
    };
};

export const getTopRanks = async (country: string, user_id: number, mods: string, gamemode: Gamemode, sort: string, order: "asc" | "desc"): Promise<PlayerTopRanksResponse> => {
    const res = await axios.post<PlayerTopRanksResponse>(`${config.api}/player/topranks`, { country, user_id, mods, gamemode, sort, order });
    return res.data;
};

export const getTopRanksNextPage = async (country: string, user_id: number, mods: string, gamemode: Gamemode, sort: string, order: "asc" | "desc", from_score: ESCountryTopScore): Promise<PlayerTopRanksResponse> => {
    return await getTopRanksNextOrPreviousPage(country, user_id, mods, gamemode, sort, order, from_score, "next");
};

export const getTopRanksPreviousPage = async (country: string, user_id: number, mods: string, gamemode: Gamemode, sort: string, order: "asc" | "desc", from_score: ESCountryTopScore): Promise<PlayerTopRanksResponse> => {
    return await getTopRanksNextOrPreviousPage(country, user_id, mods, gamemode, sort, order, from_score, "previous");
};

const getTopRanksNextOrPreviousPage = async (country: string, user_id: number, mods: string, gamemode: Gamemode, sort: string, order: "asc" | "desc", from_score: ESCountryTopScore, path: "next" | "previous"): Promise<PlayerTopRanksResponse> => {
    const res = await axios.post<PlayerTopRanksResponse>(`${config.api}/player/topranks/${path}`, {
        country,
        user_id,
        mods,
        gamemode,
        sort,
        order,
        search_after: {
            score_id: from_score.top_score.score_id,
            sort_value: getSortValueFromSortField(from_score, sort)
        }
    });

    return res.data;
};

export const convertDifficultyAttributes = (ar: number, cs: number, od: number, hp: number, mods: string): DifficultyAttributes => {
    if (mods.includes(Mod.Hardrock)) {
        ar = Math.min(ar * 1.4, 10);
        cs = Math.min(cs * 1.3, 10);
        od = Math.min(od * 1.4, 10);
        hp = Math.min(hp * 1.4, 10);
    }
    else if (mods.includes(Mod.Easy)) {
        ar *= 0.5;
        cs *= 0.5;
        od *= 0.5;
        hp *= 0.5;
    }

    if (mods.includes(Mod.DoubleTime) || mods.includes(Mod.Nightcore)) {
        const ms = convertApproachRateToMilliseconds(ar) * (2 / 3);
        ar = convertMillisecondsToApproachRate(ms);

        const hitWindow = convertOverallDifficultyToHitWindow(od) * (2 / 3);
        od = convertHitWindowToOverallDifficulty(hitWindow);
    }
    else if (mods.includes(Mod.HalfTime)) {
        const ms = convertApproachRateToMilliseconds(ar) / (3 / 4);
        ar = convertMillisecondsToApproachRate(ms);
        const hitWindow = convertOverallDifficultyToHitWindow(od) / (3 / 4);
        od = convertHitWindowToOverallDifficulty(hitWindow);
    }

    return {
        ar: +ar.toFixed(2),
        cs: +cs.toFixed(2),
        od: +od.toFixed(2),
        hp: +hp.toFixed(2),
    };
};

export const calculateBPM = (bpm: number, mods: string): number => {
    if (mods.includes(Mod.DoubleTime) || mods.includes(Mod.Nightcore))
        return bpm * 1.5;
    if (mods.includes(Mod.HalfTime))
        return bpm * 0.75;
    return bpm;
};

const convertApproachRateToMilliseconds = (ar: number): number => {
    return ar <= 5
        ? 1800 - (ar * 120)
        : 1200 - ((ar - 5) * 150);
};

const convertMillisecondsToApproachRate = (ms: number): number => {
    return ms > 1200
        ? (1800 - ms) / 120
        : (1200 - ms) / 150 + 5;
};

const convertOverallDifficultyToHitWindow = (od: number): number => {
    return 79.5 - (od * 6);
};

const convertHitWindowToOverallDifficulty = (hitWindow: number): number => {
    return (79.6 - hitWindow) / 6;
};