import React, { PropsWithChildren } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Hidden } from '@material-ui/core';

interface IProps {}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const NoMobile: React.FC<PropsType & PropsWithChildren> = (props) => {
    const { children } = props;
    return (
        <Hidden only={['xs', 'sm']}>
            {children}
        </Hidden>
    )
}

export default withStyles(styles)(NoMobile)